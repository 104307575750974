const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/verify/:token',
    name: 'verify',
    component: () => import(/* webpackChunkName: "verify" */ '../views/Verify.vue'),
    props: route => ({
      token: route.params.token,
    })
  },
  {
    path: '/apply/:token',
    name: 'apply',
    component: () => import(/* webpackChunkName: "apply" */ '../views/Apply.vue'),
    props: route => ({
      token: route.params.token,
    })
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import(/* webpackChunkName: "confirmation" */ '../views/Confirmation.vue'),
  },
  { 
    path: "*", 
    component: () => import('../views/PageNotFound.vue'),
  }
];

export default routes