<template>
  <router-view/>
</template>

<script>

export default {
  name: "App",
  metaInfo() {
    return {
      title: this.$t('common.title'),
      titleTemplate: '%s · ' + this.$t('common.title')
    }
  } 
}
</script>